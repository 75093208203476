<template>
  <div class="home">
    <BannerSlider
      v-if="0 !== contentfulStore.homeSlider.length"
      :slides="contentfulStore.homeSlider"
    />
    <RecommendedCategories :categories="getRecommendedCategories()" />
    <ProductShowcase :tabs="labelCarouselTabs" />
    <div class="home__container">
      <div class="home__wrapper">
        <ProductCarouselByLabel
          label="promotion"
          :title="_T('@Promotion slider title')"
        />
      </div>
    </div>
    <LeaveContacts />
    <div class="home__showcase">
      <ProductShowcase :tabs="getRecommendedCategories(6)" with-scroll>
        <h5 class="home__subtitle">{{ _T("@Featured") }}</h5>
      </ProductShowcase>
    </div>
    <div class="home__container">
      <InstallationSimilarPreview>
        <h5 class="home__subtitle">{{ _T("@Our objects") }}</h5>
      </InstallationSimilarPreview>
    </div>
    <NewVideo />
    <Feedback />
    <section class="home__container">
      <BlogSimilarPreview>
        <h5 class="home__subtitle">{{ _T("@Read also") }}</h5>
      </BlogSimilarPreview>
    </section>
    <Wholesale />
    <Fitters />
    <div class="home__container">
      <FaqList :faq-list="faqList">
        <template #title>
          <h2 class="home__subtitle colored">{{ _T("@FAQ") }}</h2>
        </template>
        <template #button>
          <MyLink
            class="button-primary"
            name="faq"
            :params="{ faqSlug: 'popular-questions' }"
          >
            {{ _T("@All questions") }}
          </MyLink>
        </template>
      </FaqList>
    </div>
    <Description />
  </div>
</template>

<script setup>
import logoImg from "assets/icons/Logo-GV.png";
import Description from "~/modules/home/ui/components/Description.vue";
import Feedback from "~/modules/home/ui/components/Feedback.vue";
import Fitters from "~/modules/home/ui/components/Fitters.vue";
import LeaveContacts from "~/modules/home/ui/components/LeaveContacts.vue";
import NewVideo from "~/modules/home/ui/components/NewVideo.vue";
import Wholesale from "~/modules/home/ui/components/Wholesale.vue";
import BannerSlider from "~/modules/shared/sliders/bannerSlider/BannerSlider.vue";
import { useContentfulStore } from "~/store/contentfulStore";
import BlogSimilarPreview from "~/modules/blog/components/BlogSimilarPreview.vue";
import InstallationSimilarPreview from "~/modules/installationWork/components/InstallationSimilarPreview.vue";
import ProductShowcase from "~/modules/shared/productShowcase/ProductShowcase.vue";
import ProductCarouselByLabel from "~/modules/shared/sliders/productCarousels/ProductCarouselByLabel.vue";
import FaqList from "~/modules/shared/faq/FaqList.vue";
import { useAsyncPrivateAPI } from "~/uses/useMyFetch";
import RecommendedCategories from "~/modules/home/ui/components/RecommendedCategories.vue";
import { useCategoriesStore } from "~/store/categoriesStore";

const config = useRuntimeConfig();
const route = useRoute();

const categoriesStore = useCategoriesStore();
const { getCategoriesByLabel } = storeToRefs(categoriesStore);

const contentfulStore = useContentfulStore();
await contentfulStore.fetchHomeSlider();

const labelCarouselTabs = [
  {
    title: "@Novelties",
    component: defineAsyncComponent(
      () =>
        import(
          "~/modules/shared/sliders/productCarousels/ProductCarouselByLabel.vue"
        ),
    ),
    label: "novelty",
  },
  {
    title: "@Popular",
    component: defineAsyncComponent(
      () =>
        import(
          "~/modules/shared/sliders/productCarousels/ProductCarouselByLabel.vue"
        ),
    ),
    label: "bestSeller",
  },
];

const getRecommendedCategories = (size = undefined) => {
  return getCategoriesByLabel
    .value("featured")
    .slice(0, size ?? getCategoriesByLabel.value("featured").length)
    .map((category) => {
      return {
        title: category.getName(),
        category,
        component: defineAsyncComponent(
          () =>
            import(
              "~/modules/home/ui/components/RecommendedCategoryProducts.vue"
            ),
        ),
      };
    });
};

const { data: faqList } = await useAsyncPrivateAPI("/entries", {
  params: {
    content_type: useRuntimeConfig().public.contentful.contentType.faqList,
    "fields.headings.sys.id": useRuntimeConfig().public.contentful.homeFaqId,
  },
});

useSeoMeta({
  title: _T("@Meta title"),
  description: _T("@Meta description"),
  ogUrl: config.public.appUrl + route.path,
  ogImage: logoImg,
});
</script>

<style lang="scss">
.home {
  @include flex-container(column, flex-start);
  gap: 32px;

  padding-block: 16px;

  &__container {
    @include flex-container(row, center, center);

    padding-inline: 16px;
  }

  &__wrapper {
    @extend %width-main;

    @include flex-container(column, flex-start);
    gap: 16px;
  }

  &__subtitle {
    @include font(26, 32, 600);
    align-self: flex-start;

    @include mobile {
      @include font(18, 24, 600);
    }

    &.colored {
      color: var(--color-primary-base);
    }
  }
}
</style>

<style scoped lang="scss">
.button-primary {
  width: min(265px, 100%);
  align-self: center;
}
</style>
