<template>
  <article
    class="banner-slider"
    :style="[
      { '--background-color': getBackgroundColor(slides[currentSlideIndex]) },
      { '--background-image': getBgImage(slides[currentSlideIndex]) },
    ]"
  >
    <div class="banner-slider__wrp">
      <div class="banner-slider__swiper">
        <NuxtErrorBoundary @error="() => {}">
          <Swiper
            :speed="1000"
            :navigation="{
              nextEl: '.banner-slider__btn-next',
              prevEl: '.banner-slider__btn-prev',
            }"
            :grab-cursor="true"
            :loop="true"
            :pagination="{
              el: '.swiper-pagination',
              clickable: true,
            }"
            :modules="[SwiperAutoplay, SwiperNavigation, SwiperPagination]"
            @slide-change="onSlideChange"
          >
            <SwiperSlide
              v-for="(slide, index) in getFilteredSlides(slides)"
              :key="index"
            >
              <BannerSlide :slide="slide" />
            </SwiperSlide>
          </Swiper>
        </NuxtErrorBoundary>
        <div class="pagination-slider swiper-pagination" />
        <CarouselNavigation
          v-if="slides.length > 1"
          class-name="banner-slider"
        />
      </div>
    </div>
  </article>
</template>

<script setup>
import BannerSlide from "~/modules/shared/sliders/bannerSlider/BannerSlide.vue";
import CarouselNavigation from "~/modules/shared/sliders/ui/CarouselNavigation.vue";
import { useNormalizeCtfUrl } from "~/utils/normalize";

const props = defineProps({
  slides: { type: Array, required: true },
});

const currentSlideIndex = ref(0);
const getFilteredSlides = (slides) => {
  return slides.sort((a, b) => a.position - b.position);
};

const onSlideChange = (swiper) => {
  currentSlideIndex.value = swiper.activeIndex;
};

const getBackgroundColor = (slide) => {
  return slide.colorBack ?? "transparent";
};

const getBgImage = (slide) => {
  return `url('${useNormalizeCtfUrl(slide.backgroundImage.file.url)}')`;
};
</script>

<style lang="scss">
.pagination-slider {
  .swiper-pagination-bullet {
    background-color: white;

    opacity: 1;

    transition:
      width 0.3s ease-in-out,
      background-color 0.3s ease-in-out,
      border-radius 0.3s ease-in-out;

    &.swiper-pagination-bullet-active {
      width: 16px;

      background-color: var(--color-primary-base);

      border-radius: 10px;
    }
  }
}
</style>

<style lang="scss" scoped>
.banner-slider {
  --background-color: var(--color-primary-light);
  --background-image: none;

  padding: 0 16px;

  &__wrp {
    max-width: 1440px;
    width: 100%;

    margin: 0 auto;
  }

  &__swiper {
    position: relative;

    background-color: var(--background-color);
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-size: cover;

    border-radius: 40px;

    transition: background 0.3s ease-in-out;

    .swiper-pagination {
      bottom: 24px;

      @include bigMobile {
        bottom: 0;
      }
    }
  }
}
</style>
