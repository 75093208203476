<template>
  <section class="home-description">
    <div class="home-description__container">
      <h3 class="home-description__title">{{ _T("@Description") }}</h3>
      <div class="home-description__box">
        <div
          class="home-description__visible ui-rich-text"
          :class="{ active: isShown }"
          v-html="_T('@HTML Text on the main page')"
        />
        <button class="go-to" :class="{ isShown }" @click="isShown = !isShown">
          <span>
            {{ isShown ? _T("@collapse") : _T("@expand") }}
          </span>
          <img
            src="~/assets/icons/nav-arrow-down.svg"
            alt="arrow down"
            loading="lazy"
          />
        </button>
      </div>
      <MyLink class="button-primary" name="catalog">
        <img src="~/assets/icons/catalog.svg" :alt="_T('@Goods catalog')" />
        <span>{{ _T("@Goods catalog") }} </span>
      </MyLink>
    </div>
  </section>
</template>

<script setup>
const isShown = ref(false);
</script>

<style lang="scss" scoped>
.button-primary {
  width: min(265px, 100%);
  align-self: center;
}

.home-description {
  padding: 0 16px;

  &__container {
    @extend %width-main;

    @include flex-container(column, flex-start, center);

    margin: auto;
  }

  &__title {
    @include font(26, 34, 600);
    text-align: center;

    margin-bottom: 16px;

    @include mobile {
      @include font(22, 28, 600);
      margin-bottom: 8px;
    }
  }

  &__box {
    border-radius: 24px;
    background-color: white;

    padding: 32px;
    margin-bottom: 16px;

    @include bigMobile {
      border-radius: 8px;
      padding: 16px;
    }
  }

  &__visible {
    height: 225px;

    margin-bottom: 8px;

    overflow: hidden;
    transition: height 0.4s ease-in-out;

    &.active {
      height: auto;
    }

    @include mobile {
      height: 340px;
    }
  }
}
</style>
